<template>
  <el-main class="main">
    <img class="logo-xl" src="../assets/logo.png">
    <div class="main-title main-title-name">AD Go</div>
    <div class="main-title">{{ $t('home.header') }}</div>
    <div class="wrap-card mt20" v-for="(waterfall_item,index) in waterfall_list_c" :key="index">
      <div class="fw-normal" v-if="waterfall_item.blockType===0">{{ $t('home.title0') }}</div>
      <div class="fw-normal" v-if="waterfall_item.blockType===1">
        {{ $t('home.title1_1') }}{{ waterfall_item.templateAlias }}{{ $t('home.title1_2') }}
      </div>
      <div class="fw-normal" v-if="waterfall_item.blockType===2">
        {{ $t('home.title2_1') }}{{ waterfall_item.templateAlias }}{{ $t('home.title2_2') }}
      </div>
      <div class="fw-normal" v-if="waterfall_item.blockType===3">{{ $t('home.title3') }}</div>
      <div class="fw-normal" v-if="waterfall_item.blockType===4">{{ $t('home.title5') }}</div>
      <div v-if="waterfall_item.blockType===0">{{ $t('home.guide00') }}</div>
      <div v-if="waterfall_item.blockType===0">{{ $t('home.guide01') }}</div>
      <el-input
          v-if="waterfall_item.blockType===0"
          class="simple-brief-input"
          type="textarea"
          :autosize="{ minRows: 2}"
          :placeholder="$t('home.guide02')"
          v-model="waterfall_item.content"
      >
      </el-input>
      <div class="ft-grey" v-if="waterfall_item.blockType===0">{{ $t('home.guide03') }}</div>
      <div class="ft-grey" v-if="waterfall_item.blockType===0">{{ $t('home.guide04') }}</div>
      <div class="ft-grey" v-if="waterfall_item.blockType===0">{{ $t('home.guide05') }}</div>
      <v-md-editor v-model="waterfall_item.content" mode="preview"
                   v-if="waterfall_item.blockType===1 || waterfall_item.blockType===2 || waterfall_item.blockType===3"></v-md-editor>
      <v-md-editor v-model="waterfall_item.reference" mode="preview" v-if="waterfall_item.blockType===1"></v-md-editor>
      <!--      <el-input-->
      <!--          v-if="waterfall_item.blockType===1 || waterfall_item.blockType===2 || waterfall_item.blockType===3"-->
      <!--          class="simple-brief-input"-->
      <!--          type="textarea"-->
      <!--          :autosize="{ minRows: 2}"-->
      <!--          v-model="waterfall_item.content">-->
      <!--      </el-input>-->
      <mark-down v-if="waterfall_item.blockType===4" :source="waterfall_item.content"></mark-down>
      <hr v-if="waterfall_item.blockType===1 || waterfall_item.blockType===2 || waterfall_item.blockType===3 || waterfall_item.blockType===4">
      <div v-if="waterfall_item.blockType===1">{{ $t('home.guide10') }}</div>
      <div v-if="waterfall_item.blockType===1">
        {{ $t('home.guide11_1') }}
        <span class="my-button"
              @click="briefReference(last_block.briefTemplateUrl)">{{ waterfall_item.briefTemplateFilename }}</span>
        {{ $t('home.guide11_2') }}
      </div>
      <div v-if="waterfall_item.blockType===1">
        {{ $t('home.guide12_1') }}
        <span class="fw-bold"> {{ $t('home.guide12_2') }}</span>
      </div>
      <div v-if="waterfall_item.blockType===1">
        {{ $t('home.guide13_1') }}
        <span class="my-button ft-grey">{{ last_block.planTypeName }}</span>
        {{ $t('home.guide13_2') }}
      </div>
      <div v-if="waterfall_item.blockType===2">{{ $t('home.guide20') }}</div>
      <div v-if="waterfall_item.blockType===2">
        {{ $t('home.guide21') }}
        <span class="my-button" @click="downloadFile(waterfall_item.id)">{{ $t('home.button20') }}</span>
        {{ $t('home.guide23') }}
      </div>
      <div v-if="waterfall_item.blockType===2">
        {{ $t('home.guide24') }}
        <span class="my-button ft-grey">{{ $t('home.button21') }}</span>
        <b>{{ $t('home.guide26') }}</b>
        {{ $t('home.guide27') }}
        <b>{{ $t('home.guide28') }}</b>。
      </div>
      <div v-if="waterfall_item.blockType===2">{{ $t('home.guide36') }} <span class="my-button"
                                                                              @click="translatePlan(waterfall_item)">{{
          $t('home.button37')
        }}</span></div>
      <div v-if="waterfall_item.blockType===2">{{ $t('home.guide35') }} <span
          class="my-button ft-grey">{{ $t('home.button00') }}</span></div>
      <div v-if="waterfall_item.blockType===3 || waterfall_item.blockType===4">
        <span class="my-button" @click="downloadFile(waterfall_item.id)">{{ $t('home.button20') }}</span>
      </div>
    </div>
    <div class="wrap-card mt20" v-if="!last_task || last_task.status==100 || last_task.status==1">
      <div class="fw-normal" v-if="last_block.blockType===0">{{ $t('home.title0') }}</div>
      <div class="fw-normal title-with-button" v-if="last_block.blockType===1">
        <div>{{ $t('home.title1_1') }}{{ last_block.templateAlias }}{{ $t('home.title1_2') }}</div>
        <div>
          <el-button icon="el-icon-edit" type="primary" size="mini" v-if="!last_block_edit" @click="startEdit">{{ $t('workspace.edit.start') }}</el-button>
          <el-button icon="el-icon-edit" type="primary" size="mini" v-if="last_block_edit" @click="finishEdit">{{ $t('workspace.edit.finish') }}</el-button>
        </div>
      </div>
      <div class="fw-normal" v-if="last_block.blockType===2">{{ $t('home.title2_1') }}{{
          last_block.templateAlias
        }}{{ $t('home.title2_2') }}
      </div>
      <div class="fw-normal" v-if="last_block.blockType===3">{{ $t('home.title3') }}</div>
      <div class="fw-normal" v-if="last_block.blockType===4">{{ $t('home.title5') }}</div>
      <div v-if="last_block.blockType===0">{{ $t('home.guide00') }}</div>
      <div v-if="last_block.blockType===0">{{ $t('home.guide01') }}</div>
      <el-input
          v-if="last_block.blockType===0"
          class="simple-brief-input"
          type="textarea"
          :autosize="{ minRows: 1}"
          :placeholder="$t('home.guide02')"
          v-model="last_block.content"
      >
      </el-input>
      <div class="ft-grey" v-if="last_block.blockType===0">{{ $t('home.guide03') }}</div>
      <div class="ft-grey" v-if="last_block.blockType===0">{{ $t('home.guide04') }}</div>
      <div class="ft-grey" v-if="last_block.blockType===0">{{ $t('home.guide05') }}</div>
      <v-md-editor v-model="last_block.content" mode="preview" v-if="last_block.blockType===1 && !last_block_edit"></v-md-editor>
      <v-md-editor v-model="last_block.content" height="800px" mode="editable" v-if="last_block.blockType===1  && last_block_edit"
                   @save="handleSave" left-toolbar="undo redo | h bold | save" right-toolbar="fullscreen"></v-md-editor>
      <v-md-editor v-model="last_block.content" mode="preview"
                   v-if="last_block.blockType===2 || last_block.blockType===3"></v-md-editor>
      <div class="fw-normal title-with-button" v-if="last_block.blockType===1">
        <div> </div>
        <div>
          <el-button icon="el-icon-edit" type="primary" size="mini" v-if="!last_block_edit" @click="startEdit">{{ $t('workspace.edit.start') }}</el-button>
          <el-button icon="el-icon-edit" type="primary" size="mini" v-if="last_block_edit" @click="finishEdit">{{ $t('workspace.edit.finish') }}</el-button>
        </div>
      </div>
      <v-md-editor v-model="last_block.reference" mode="preview" v-if="last_block.blockType===1"></v-md-editor>

      <!--      <el-input-->
      <!--          v-if="last_block.blockType===1 || last_block.blockType===2 || last_block.blockType===3"-->
      <!--          class="simple-brief-input"-->
      <!--          type="textarea"-->
      <!--          :autosize="{ minRows: 2}"-->
      <!--          v-model="last_block.content">-->
      <!--      </el-input>-->
      <mark-down v-if="last_block.blockType===4" :source="last_block.content"></mark-down>

      <hr v-if="last_block.blockType===1 || (last_block.blockType===2) || last_block.blockType===3">
      <div v-if="last_block.blockType===2 && last_task.status==1">{{ $t('home.waiting1') }}{{
          waiting_time
        }}{{ $t('home.waiting2') }}<span>{{ loading_animation }}</span>
      </div>
      <div v-if="last_block.blockType===2 && last_task.status==1">{{ $t('home.waiting3') }}</div>
      <div class="txt-right" v-if="last_block.blockType===0">
        <el-popover
            width="600"
            v-model="v_template_pop">
          <div class="ft18">{{ $t('home.title4') }}</div>
          <div class="ft18" v-for="(template_item,index) in template_list" :key="index">
            <p><span class="my-button" @click="addAndGenerateBrief(template_item.id)">{{ template_item.name }}</span>
            </p>
            <p>{{ template_item.description }}</p>
          </div>
          <span slot="reference" class="my-button">{{ $t('home.button00') }}</span>
        </el-popover>
      </div>
      <div v-if="last_block.blockType===1">{{ $t('home.guide10') }}</div>
      <div v-if="last_block.blockType===1">
        {{ $t('home.guide11_1') }}
        <span class="my-button"
              @click="briefReference(last_block.briefTemplateUrl)">{{ last_block.briefTemplateFilename }}</span>
        {{ $t('home.guide11_2') }}
      </div>
      <div v-if="last_block.blockType===1">
        {{ $t('home.guide12_1') }}
        <span class="fw-bold"> {{ $t('home.guide12_2') }}</span>
      </div>
      <div v-if="last_block.blockType===1">
        {{ $t('home.guide13_1') }}
        <span class="my-button" @click="generatePlan(last_block.templateId)">{{ last_block.planTypeName }}</span>
        {{ $t('home.guide13_2') }}
      </div>
      <div v-if="last_block.blockType===2 && last_task.status==100">{{ $t('home.guide20') }}</div>
      <div v-if="last_block.blockType===2 && last_task.status==100">
        {{ $t('home.guide21') }}
        <span class="my-button" @click="downloadFile(last_block.id)">{{ $t('home.button20') }}</span>
        {{ $t('home.guide23') }}
      </div>
      <div v-if="last_block.blockType===2 && last_task.status==100">
        {{ $t('home.guide24') }}
        <span class="my-button" @click="backToBrief">{{ $t('home.button21') }}</span>
        <b>{{ $t('home.guide26') }}</b>
        {{ $t('home.guide27') }}
        <b>{{ $t('home.guide28') }}</b>。
      </div>
      <div v-if="last_block.blockType===2 && last_task.status==100">{{ $t('home.guide36') }} <span class="my-button"
                                                                                                   @click="translatePlan(last_block)">{{
          $t('home.button37')
        }}</span></div>
      <div v-if="last_block.blockType===2 && last_task.status==100">{{ $t('home.guide35') }}
        <el-popover
            width="600"
            v-model="v_template_pop">
          <div class="ft18">{{ $t('home.title4') }}</div>
          <div class="ft18" v-for="(template_item,index) in template_list" :key="index">
            <p v-if="template_item.id!=last_block.templateId"><span class="my-button"
                                                                    @click="generateOtherBrief(template_item.id)">{{
                template_item.name
              }}</span></p>
            <p v-if="template_item.id!=last_block.templateId">{{ template_item.description }}</p>
          </div>
          <span slot="reference" class="my-button">{{ $t('home.button00') }}</span>
        </el-popover>
      </div>

      <div v-if="last_block.blockType===3 || last_block.blockType===4">
        <span class="my-button" @click="downloadFile(last_block.id)">{{ $t('home.button20') }}</span>
      </div>
      <div v-if="last_block.blockType===3 || last_block.blockType===4">
        <span class="my-button" @click="backToPlan">{{ $t('home.button21') }}</span>
      </div>
    </div>
    <div class="wrap-card mt20 ft-grey" v-if="last_task && last_task.status===0">
      <div class="fw-normal" v-if="last_block.blockType===1">
        {{ $t('home.title1_1') }}{{ last_block.templateAlias }}{{ $t('home.title1_2') }}
      </div>
      <div class="fw-normal" v-if="last_block.blockType===2">{{ $t('home.title2_1') }}{{
          last_block.templateAlias
        }}{{ $t('home.title2_2') }}
      </div>
      <div class="fw-normal" v-if="last_block.blockType===3">{{ $t('home.title3') }}</div>
      <div class="fw-normal" v-if="last_block.blockType===4">{{ $t('home.title5') }}</div>
      <div>{{ $t('home.waiting1') }}{{ waiting_time }}{{ $t('home.waiting2') }}<span>{{ loading_animation }}</span>
      </div>
      <div>{{ $t('home.waiting3') }}</div>
    </div>
    <div class="wrap-card mt20" v-if="last_task && last_task.status===-1 && last_block.retryTimes<1">
      <div class="fw-normal" v-if="last_block.blockType===1">
        {{ $t('home.title1_1') }}{{ last_block.templateAlias }}{{ $t('home.title1_2') }}
      </div>
      <div class="fw-normal" v-if="last_block.blockType===2">{{ $t('home.title2_1') }}{{
          last_block.templateAlias
        }}{{ $t('home.title2_2') }}
      </div>
      <div class="fw-normal" v-if="last_block.blockType===3">{{ $t('home.title3') }}</div>
      <div class="fw-normal" v-if="last_block.blockType===4">{{ $t('home.title5') }}</div>
      <div>{{ $t('home.error01') }} <span class="my-button" @click="redoTask">{{ $t('home.errorbutton01') }}</span>
      </div>
      <div>{{ $t('home.error03') }}</div>
    </div>
    <div class="wrap-card mt20" v-if="last_task && last_task.status===-1 && last_block.retryTimes>=1">
      <div class="fw-normal" v-if="last_block.blockType===1">
        {{ $t('home.title1_1') }}{{ last_block.templateAlias }}{{ $t('home.title1_2') }}
      </div>
      <div class="fw-normal" v-if="last_block.blockType===2"> {{ $t('home.title2_1') }}{{
          last_block.templateAlias
        }}{{ $t('home.title2_2') }}
      </div>
      <div class="fw-normal" v-if="last_block.blockType===3">{{ $t('home.title3') }}</div>
      <div class="fw-normal" v-if="last_block.blockType===4">{{ $t('home.title5') }}</div>
      <div>{{ $t('home.error11') }}</div>
      <div>{{ $t('home.error12') }}</div>
    </div>
  </el-main>
</template>

<script>
import {EventBus} from '../utils/event-bus'
import MarkDown from 'vue-markdown'

export default {
  name: "Workspace",
  components: {
    MarkDown
  },
  data: function () {
    return {
      v_loading: false,
      v_template_pop: false,
      loading_animation: '',
      waiting_time: 999,
      disable_upload: true,
      waterfall_list: [],
      last_block: {
        blockType: 0,
        content: '',
        reference: '',
        retryTimes: 0
      },
      last_task: null,
      current_brief_id: 0,
      file_list: [],
      upload_url: process.env.VUE_APP_API_BASE_URL + "/file/upload",
      uploading_file_count: 0,
      template_list: [],
      last_block_edit: false
    }
  },
  computed: {
    waterfall_list_c: function () {
      let me = this
      let waterfall_list_t = JSON.parse(JSON.stringify(me.waterfall_list))
      if (me.$i18n.locale === 'zh-tw') {
        waterfall_list_t.forEach(item => {
          item.content = me.translation(item.content);
          if (item.templateAlias) {
            item.templateAlias = me.translation(item.templateAlias)
          }

        });
      }
      return waterfall_list_t
    }
  },
  watch: {
    '$route': function (to) {
      let brief_id = to.query['brief_id']
      if (brief_id) {
        if (!this.current_brief_id || this.current_brief_id != brief_id) {
          this.current_brief_id = brief_id
          this.loadWaterfall()
        }
      } else {
        this.reset()
      }
    }
  },
  mounted() {
    this.loadTemplateList()
    let brief_id = this.$route.query['brief_id']
    if (brief_id) {
      this.current_brief_id = brief_id
      this.loadWaterfall()
    } else {
      this.reset()
    }
  },
  methods: {
    loadWaterfall() {
      let me = this
      let data = {
        briefId: this.current_brief_id
      }
      this.axios.post('/waterfall/list', data).then((res) => {
        if (res.data.waterfalls.length > 0) {
          let waterfalls = res.data.waterfalls
          me.last_block = waterfalls.pop()
          if (me.$i18n.locale === 'zh-tw') {
            me.last_block.content = me.translation(me.last_block.content);
          }
          me.waterfall_list = waterfalls
          me.waiting_time = res.data.waitingTime
          me.last_task = res.data.lastTask
          if (me.last_task && me.last_task.status == 0 || me.last_task.status == 1) {
            me.loadingAnimation()
            me.refreshLastBlock()
          }
        } else {
          me.waterfall_list = []
          me.waiting_time = 999
          me.last_block = {
            blockType: 0,
            content: '',
            reference: '',
            retryTimes: 0
          }
        }
      })
    },
    loadTemplateList() {
      let me = this
      this.axios.get("/template/list").then((res) => {
        let temp_list = res.data
        if (me.$i18n.locale === 'zh-tw') {
          temp_list.forEach((item) => {
            item.name = me.translation(item.name)
          })
        }
        me.template_list = temp_list
      })
    },
    addBrief(callback, templateId) {
      let me = this
      if (!this.last_block.content) {
        this.$message.error("输入不能为空")
        return
      }
      let data = {
        simpleBrief: this.last_block.content,
      }
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.axios.post("/brief/add", data).then((res) => {
        me.current_brief_id = res.data.id
        me.$router.push({
          path: "/home/workspace",
          query: {
            brief_id: me.current_brief_id
          }
        })
        EventBus.$emit('add-brief', res.data)
        loading.close()
        if (callback) {
          callback(templateId)
        }
      })
    },
    addAndGenerateBrief(templateId) {
      if (!this.last_block.content) {
        this.$message.error(this.$t('home.errmsg01'))
        return
      }
      if (!this.current_brief_id) {
        this.addBrief(this.generateBrief, templateId)
      } else {
        this.generateBrief(templateId)
      }
    },

    generateBrief(templateId) {
      let me = this
      if (!this.last_block.content) {
        this.$message.error("输入不能为空")
        return
      }
      let data = {
        briefId: this.current_brief_id,
        simpleBrief: this.last_block.content,
        templateId: templateId,
        saveSimpleBrief: true
      }
      this.axios.post("/waterfall/brief/add", data).then((res) => {
        let block = {...me.last_block}
        me.waterfall_list.push(block)
        me.last_block = res.data.waterfall
        if (me.$i18n.locale === 'zh-tw') {
          me.last_block.content = me.translation(me.last_block.content);
        }
        me.last_task = res.data.lastTask
        me.waiting_time = res.data.waitingTime
        me.loadingAnimation()
        me.refreshLastBlock()
      })
    },
    generateOtherBrief(templateId) {
      let me = this
      let simpleBrief = ""
      for (let i = this.waterfall_list.length - 1; i >= 0; i--) {
        if (this.waterfall_list[i].blockType == 0) {
          simpleBrief = this.waterfall_list[i].content
        }
      }
      let data = {
        briefId: this.current_brief_id,
        simpleBrief: simpleBrief,
        templateId: templateId,
        saveSimpleBrief: false
      }
      this.axios.post("/waterfall/brief/add", data).then((res) => {
        let block = {...me.last_block}
        me.waterfall_list.push(block)
        me.last_block = res.data.waterfall
        if (me.$i18n.locale === 'zh-tw') {
          me.last_block.content = me.translation(me.last_block.content);
        }
        me.last_task = res.data.lastTask
        me.waiting_time = res.data.waitingTime
        me.loadingAnimation()
        me.refreshLastBlock()
      })
    },
    generatePlan(template_id) {
      let me = this
      if (!this.last_block.content) {
        this.$message.error("输入不能为空")
        return
      }
      this.$confirm(this.$t('workspace.confirm.message'), this.$t('workspace.confirm.title'), {
        confirmButtonText: this.$t('workspace.confirm.ok_button'),
        cancelButtonText: this.$t('workspace.confirm.cancel_button'),
        type: 'warning',
        closeOnClickModal: false
      }).then(() => {
        let data = {
          briefId: me.current_brief_id,
          waterfallId: me.last_block.id,
          templateId: template_id,
          briefContent: me.last_block.content
        }
        me.axios.post("/waterfall/plan/add", data).then((res) => {
          let block = {...me.last_block}
          me.waterfall_list.push(block)
          me.last_block = res.data.waterfall
          if (me.$i18n.locale === 'zh-tw') {
            me.last_block.content = me.translation(me.last_block.content);
          }
          me.last_task = res.data.lastTask
          me.waiting_time = res.data.waitingTime
          me.loadingAnimation()
          me.refreshLastBlock()
        })
      })
    },
    translatePlan(block) {
      let me = this
      let data = {
        id: block.id,
        briefId: this.current_brief_id,
        content: block.content
      }
      this.axios.post("/waterfall/plan/translate", data).then((res) => {
        let block = {...me.last_block}
        me.waterfall_list.push(block)
        me.last_block = res.data.waterfall
        me.last_task = res.data.lastTask
        me.waiting_time = res.data.waitingTime
        me.loadingAnimation()
        me.refreshLastBlock()
      })
    },
    redoTask() {
      let me = this
      let data = {
        waterfallId: this.last_block.id,
        taskId: this.last_task.id
      }
      this.axios.post("/waterfall/task/redo", data).then((res) => {
        me.last_block = res.data.waterfall
        if (me.$i18n.locale === 'zh-tw') {
          me.last_block.content = me.translation(me.last_block.content);
        }
        me.last_task = res.data.lastTask
        me.waiting_time = res.data.waitingTime
        me.loadingAnimation()
        me.refreshLastBlock()
      })
    },
    backToBrief() {
      let me = this
      let last_brief = ''
      let last_reference = ''
      let template_id = 0
      for (let i = me.waterfall_list.length - 1; i >= 0; i--) {
        if (me.waterfall_list[i].blockType === 1) {
          last_brief = me.waterfall_list[i].content
          last_reference = me.waterfall_list[i].reference
          template_id = me.waterfall_list[i].templateId
          break
        }
      }
      let data = {
        briefId: this.current_brief_id,
        blockType: 1,
        content: last_brief,
        reference: last_reference,
        templateId: template_id
      }
      me.axios.post("/waterfall/block/add", data).then((res) => {
        let block = {...me.last_block}
        me.waterfall_list.push(block)
        me.last_block = res.data
        if (me.$i18n.locale === 'zh-tw') {
          me.last_block.content = me.translation(me.last_block.content);
        }
        me.last_task = null
      })
    },
    backToPlan() {
      let me = this
      let last_plan = {
        content: '',
        taskId: 0
      }
      for (let i = me.waterfall_list.length - 1; i >= 0; i--) {
        if (me.waterfall_list[i].blockType === 2) {
          last_plan = me.waterfall_list[i]
          break
        }
      }
      let data = {
        briefId: this.current_brief_id,
        blockType: 2,
        content: last_plan.content,
        taskId: last_plan.taskId
      }
      me.axios.post("/waterfall/block/add", data).then((res) => {
        let block = {...me.last_block}
        me.waterfall_list.push(block)
        me.last_block = res.data
        if (me.$i18n.locale === 'zh-tw') {
          me.last_block.content = me.translation(me.last_block.content);
        }
        me.last_task = null
      })
    },
    refreshLastBlock() {
      let me = this
      let interval_id = setInterval(function () {
        if (me.waiting_time > 1) {
          me.waiting_time--
        }
        me.getLastBlock(me.last_block.id, interval_id)
      }, 60000)
    },
    getLastBlock(waterfall_id, interval_id) {
      let me = this
      let data = {
        waterfallId: waterfall_id
      }
      this.axios.post("/waterfall/block/get", data).then((res) => {
        me.last_block = res.data.waterfall
        if (me.$i18n.locale === 'zh-tw') {
          me.last_block.content = me.translation(me.last_block.content);
        }
        me.last_task = res.data.lastTask
        if (me.last_task.status !== 0 && me.last_task.status !== 1) {
          me.v_loading = false
          clearInterval(interval_id)
        }
      })
    },
    downloadFile(waterfall_id) {
      let me = this;
      let data = {
        waterfallId: waterfall_id
      }
      let config = {
        responseType: 'blob'
      }
      this.downloader.post("/waterfall/plan/download", data, config).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        let filename = "plan_" + (new Date()).getTime() + ".docx";
        link.setAttribute('download', filename); // or any other extension
        document.body.appendChild(link);
        link.click();
      }).catch((err) => {
        console.log(err)
        me.$message.error(this.$t('home.errmsg02'))
      })
    },
    briefReference(url) {
      window.open(url, '_blank')
    },
    reset() {
      this.current_brief_id = 0
      this.waterfall_list = []
      this.last_block = {
        blockType: 0,
        content: '',
        retryTimes: 0
      }
      this.last_task = null
    },
    loadingAnimation() {
      let me = this
      this.v_loading = true
      let interval_id = setInterval(function () {
        if (!me.v_loading) {
          clearInterval(interval_id)
        }
        if (me.loading_animation.length === 3) {
          me.loading_animation = ""
        }
        me.loading_animation = me.loading_animation + "。"
      }, 500)
    },
    handleSave() {
      let me = this
      this.axios.post("/waterfall/block/update", this.last_block).then(() => {
        me.$message.success(this.$t('workspace.save.success'))
      })
    },
    startEdit(){
      this.last_block_edit=true
    },
    finishEdit(){
      this.last_block_edit=false
    }
  }
}
</script>

<style scoped>

.main {
  background-color: #FFFBEB;
  padding: 0.2rem 0 0.2rem 0.2rem;
  font-size: 0.18rem;
  color: #000000;
}

.main ::-webkit-scrollbar {
  display: none;
}

.logo-xl {
  width: 1rem;
  height: 1rem;
  display: block;
  margin: 0.4rem auto 0;
}

.brief-item i {
  margin-left: 0.2rem;
}

.main-title {
  width: 100%;
  font-size: 0.18rem;
  color: #000000;
  text-align: center;
  line-height: 1.5;
  font-weight: lighter;
}

.main-title-name {
  font-weight: normal;
  font-size: 0.24rem;
  margin-top: 0.2rem;
}

.wrap-card {
  background: #ffffff;
  padding: 0.2rem;
  border: 1px solid #E7E6E6;
  border-radius: 0.05rem;
  box-shadow: 0.02rem 0.02rem 0.05rem 0 rgba(0, 0, 0, 0.5);
  line-height: 2;
  margin-right: 0.05rem;
  font-weight: lighter;
}

.mr20 {
  margin-right: 0.2rem;
}

.mt20 {
  margin-top: 0.2rem;
}

.mt40 {
  margin-top: 0.4rem;
}

.my-button {
  color: #00B0F0;
  text-decoration: underline;
  cursor: pointer;
}

.simple-brief-input {
  font-size: 0.18rem;
}

/deep/ .simple-brief-input .el-textarea__inner {
  font-size: 0.18rem;
  font-family: Söhne, ui-sans-serif, system-ui, -apple-system, "Segoe UI", Roboto, Ubuntu, Cantarell, "Noto Sans", sans-serif, "Helvetica Neue", Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: lighter;
  color: #000000;
}

/deep/ .simple-brief-input .el-textarea__inner {
  border: none;
  padding: 0;
  line-height: 2;
}

.ft-grey {
  color: #A6A6A6;
  cursor: auto;
}

.txt-right {
  text-align: right;
}

.fw-normal {
  font-weight: normal;
}

.fw-bold {
  font-weight: bold;
}

.ft18 {
  font-size: 0.18rem;
}

.title-with-button{
  display: flex;
  justify-content: space-between;
}
</style>
